<template>
    <div class="my-order">
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <div class="record-list">
            <div v-for="(item,index) in recordList" :key="index" class="record-item">
                <div class="info-item">
                    <div class="label">{{$t('Trade.Time')}}</div>
                    <div class="value">{{item.created_at}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Currency')}}</div>
                    <div class="value">{{item.type == 2 ? item.to : item.from}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.type')}}</div>
                    <div class="value" :style="{ color: item.type == 2 ? '#01BD8B' : '#FB474E' }">
                        {{item.type == 2 ? $t('CurrencyTrading.Buy') : $t('CurrencyTrading.Sell')}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Price')}}</div>
                    <div class="value">{{item.type == 2 ? parseFloat(item.to_rate) : parseFloat(item.from_rate)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Quantity')}}</div>
                    <div class="value">{{item.type == 2 ? parseFloat(item.to_amount) : parseFloat(item.from_amount)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('CurrencyTrading.Status')}}</div>
                    <div class="value" :style="{ color: '#01BD8B' }">{{$t('CurrencyTrading.Success')}}</div>
                </div>
            </div>
        </div>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination small @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="5"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next"
                :total="total" background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabList: [this.$t('CurrencyTrading.OrderHistory')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updateTradeOrder')
            this.$bus.$on('updateTradeOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        methods: {
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.tradeOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        margin-top: 4px;
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 3.5rem;
                line-height: 3.5rem;
                box-sizing: border-box;
                padding: 0 1.2rem;
                font-size: 1.3rem;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        .record-list {
            width: 100%;
            
            .record-item {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                border-top: 1px solid #EBEEF5;
                background-color: #FFFFFF;
                
                .info-item {
                    width: 48%;
                    margin-left: 4%;
                    
                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }
                    
                    &:nth-child(n+3) {
                        margin-top: 1.6rem;
                    }
                    
                    .label {
                        width: 100%;
                        margin-bottom: .8rem;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        width: 100%;
                        font-size: 1.2rem;
                        color: #000000;
                        word-break: break-all;
                    }
                    
                    .profit {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                }
            }
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>